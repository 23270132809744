//Preloader -------------------------
$(window).load(function () {
    $("[data-toggle]").click(function () {
        var toggle_el = $(this).data("toggle");
        //$(toggle_el).toggleClass("open-sidebar");
    });

    $('#status').fadeOut();

    var hash = window.location.hash;
    var $navigator = navigator.userAgent;


    if (hash === '#services' && $navigator.toLowerCase().indexOf('firefox') !== -1) {
        $('html,body').animate({
                scrollTop: $(hash).offset().top
            }, 0
        );
    }
    $('#preloader').delay(350).fadeOut('slow');


    /*setTimeout(function () {
        var $filter = window.location.href.split('#')[1];
        var $filtersList = $('.work-filter');

        if ($filter !== undefined && $filter !== '' && $filtersList) {
            $filtersList.each(function () {
                if ($(this).data('filter').indexOf($filter) >= 0) {
                    var $this = $(this);
                    $this.click();
                    if (!lib.isMobile) {
                        $('.toggle-wfilter').css({'opacity': '1', 'right':'70px'});
                        $this.closest('div.parents-toggle').find('a').first().click();
                    }
                }
            });
        }
    }, 10);*/

    $('#wrapper').css('overflow', 'hidden');

});

var obj = window;

$(document).ready(function () {


    /* FADEOUT SOCIAL-TRIGGER ----------------------  */

    var $socialBtn = $(".social-trigger");

    function hide_scroll(){
        if( $(window).scrollTop() > 30 ){
            $socialBtn.removeClass('in', 2000);
            $socialBtn.addClass('out', 2000);
        }else{
            $socialBtn.removeClass('out', 2000);
            $socialBtn.addClass('in', 2000);
        }
    }

    $(window).bind('scroll', hide_scroll); 


    $('.work-filter').click(function () {
        setTimeout(function () {
            $('html').getNiceScroll().resize();
        }, 1000);
    });

    positionShare();

    function positionShare () {
        if ($('.share-btn').length == 0) {
            return;
        }

        var share = $('.share-toggle');
        var shareBtn = $('.share-btn');

        share.offset({
            top: shareBtn.offset().top + shareBtn.height()/2 - 15,
            left: shareBtn.offset().left - share.width()
        });
    }

    $(window).resize(function () {
        positionShare();
    });

    if ($('#fullpage').length !== 0) {
        $('#fullpage').fullpage({
            anchors: ['firstPage'],
            sectionsColor: false,
            autoScrolling: false,
            css3: true
        });
    }

    $("[id^='video-']").each(function () {
        $(this).get(0).play();
    });

    var $navBtn = $("#sidebar-toggle");
    var menu = $('nav.menu');
    var menuTimer;
    var $socialTrigger = $(".social-trigger");

    /* SIDEBAR > MENU ------------------------------- */
    $navBtn.on('click', function (e) {
        $('.overlay-share').fadeOut();
        $(".toggle-wfilter").stop().animate({right: '-250px',opacity:0});
        //$('#sidebar-toggle').removeClass("appear");
        lib.noSwipe = false;
        clearInterval(menuTimer);
        var prevPos = menu.offset().left;
        $(this).toggleClass('slide-open');

        var posLeft = 240;
        if ($(window).width() < 340) {
            posLeft = 195;
        }
        else if ($(window).width() < 480){
            posLeft = 235;
        }

        if ($(this).hasClass('slide-open')) {
            lib.swipe = 'right';
            $(this).animate({"top": "-51px"}, 800, "easeInOutExpo");
            $('.social-trigger').addClass("hide");

            $('.logo').animate(
                {
                    "left": 10,
                    opacity: 1
                }, {
                    duration: 175,
                    queue: false
                }
            );
            $('.menu').animate(
                {
                    "left": 0,
                    opacity: 1
                }, {
                    duration: 500,
                    queue: false
                }
            );

            $('.container').animate(
                {
                    "left": posLeft,
                    opacity: 1
                }, {
                    duration: 100,
                    queue: false
                }
            );

            $navBtn.animate(
                {
                    "left": posLeft,
                    opacity: 1
                }, {
                    duration: 150,
                    queue: false
                }
            );

            $socialTrigger.animate(
                {
                    opacity: lib.isMobile ? 0 : 1,
                    "left": lib.isMobile ? 0 : posLeft
                }, {
                    duration: 150,
                    queue: false
                });

        } else {
            lib.swipe = 'left';
            //$(this).animate({"top": "0"}, 800, "easeInOutExpo");
            $('.social-trigger').removeClass("hide");

            $('.logo').animate(
                {
                    "left": -300,
                    opacity: 0
                }, {
                    duration: 100,
                    queue: false
                }
            );
            $('.menu').animate(
                {
                    "left": -300,
                    opacity: 0
                }, {
                    duration: 500,
                    queue: false
                }
            );

            $('.container').animate(
                {
                    "left": 0,
                    opacity: 1
                }, {
                    duration: 100,
                    queue: false
                }
            );

            $navBtn.animate(
                {
                    "left": 0,
                    opacity: 1
                }, {
                    duration: 100,
                    queue: false
                }
            );

            $socialTrigger.animate(
                {
                    opacity: 1,
                    "left": 0
                }, {
                    duration: 100,
                    queue: false
                });

        }

        //nav_move();

        e.preventDefault();
    });

    /* SIDEBAR > MENU ------------------------------- */
    function closeMenu() {
        $('.overlay-share').fadeOut();
        $(".toggle-wfilter").stop().animate({right: '-250px',opacity:0});

        lib.noSwipe = false;
        clearInterval(menuTimer);
        $('#sidebar-toggle').removeClass('slide-open');

        lib.swipe = 'left';
        //$(this).animate({"top": "0"}, 800, "easeInOutExpo");
        $('.social-trigger').removeClass("hide");

        $('.logo').animate(
            {
                "left": -300,
                opacity: 0
            }, {
                duration: 100,
                queue: false
            }
        );
        $('.menu').animate(
            {
                "left": -300,
                opacity: 0
            }, {
                duration: 500,
                queue: false
            }
        );

        $('.container').animate(
            {
                "left": 0,
                opacity: 1
            }, {
                duration: 100,
                queue: false
            }
        );

        $navBtn.animate(
            {
                "left": 0,
                opacity: 1
            }, {
                duration: 100,
                queue: false
            }
        );

        $socialTrigger.animate(
            {
                opacity: 1,
                "left": 0
            }, {
                duration: 100,
                queue: false
            }
        );
    }

    var $shareBtn = $(".share-btn");
    var $shareToggle = $(".share-toggle");

    //$shareToggle.fadeOut(), 2500;

    $shareBtn.click(function() {
        $shareToggle.toggleClass("open-share");
        $shareBtn.toggleClass("opacity","no-color");
    });



    /* ANCHOR SCROLL -------------------------------- */
    jQuery.fn.anchorAnimate = function (settings) {

        settings = jQuery.extend({
            speed: 1100
        }, settings);

        return this.each(function () {
            var caller = this;
            $(caller).click(function (event) {
                event.preventDefault();
                var locationHref = window.location.href;
                var elementClick = $(caller).attr("href");
                closeMenu();

                var destination = $(elementClick).offset().top;
                $("body, html").animate({scrollTop: destination}, settings.speed, function () {
                    window.location.hash = elementClick;
                });
                return false;
            })
        })
    };

    var $anchor = $(".goto");
    $anchor.anchorAnimate({speed: 500});

    /* INVIEW - ANIMATION  ------------------------------------ */
    var $anim = $(".animated");

    $anim.css('opacity', '0');
    $anim.bind('inview', function (event, isVisible, visibleX, visibleY) {

        if (isVisible && $(this).hasClass('fadeInUp') === false) {
            $anim.each(function () {
                $(this).stop().animate({opacity: "1"}, 300);
                $(this).addClass('fadeInUp');
            });
        }
       /* else {
            $(this).stop().animate({opacity: "0"}, 300);
            $(this).removeClass('fadeInUp');
        }*/
    });


    /* LINKS ACTIVE ---------------------------------- */
    var $linkactive = $("#sidebar nav.menu li a, .menu2 ul a");
    $linkactive.click(function () {
        $linkactive.removeClass("active");
        $(this).addClass("active");
    });


    /* TOUCH EVENT ----------------------------------- */
    $('.taphover').on("touchstart", function (e) {
        "use strict";
        var link = $(this);
        if (link.hasClass('hover')) {
            link.removeClass('hover');
            return true;
        } else {
            link.parent().removeClass('web');
            link.addClass("hover");
            return true;
        }
    });


    /* SHARE main -------------------------- */
    $(".toggle-share, .overlay-share").click(function () {
        $(".overlay-share").fadeToggle(), 1500;
        $("#sidebar-toggle, .toggle-share").toggleClass("appear");
    });
    $(".follow-us").click(function () {
        $(".overlay-share").fadeToggle(), 1500;
    });

    // Cookie disclaimer
    var cookieName = '3lpDisc';
    if (!getCookie(cookieName)) {
        $('#cookie-bar').removeClass('hidden');
        $('.acceptDisc').on('click', function (e) {
            e.preventDefault();
            setCookie(cookieName, true, 365);
            $('#cookie-bar').fadeOut('slow');
        });
    }
    $('.close-disc').on('click', function (e) {
        e.preventDefault();
        $('#cookie-bar').addClass('hidden');
    });
});

// Cookie disclaimer functions
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

//* PARALLAX ------------------------------------------- */
var big_image;
$(document).ready(function () {
    responsive = $(window).width();

    big_image = $(".parallax-background").find('img');
    $(window).on('scroll', function () {
        parallax();
    });

});

var parallax = function () {
    var current_scroll = $(this).scrollTop();

    oVal = ($(window).scrollTop() / 3);
    big_image.css('top', oVal);
};

/* MAC-PC CLASS ------------------------------------ */
$(window).load(function(){
        if (navigator.appVersion.indexOf("Mac")!=-1) {
            $('body').addClass('mac');
        } else {
            $('body').addClass('pc');
        }
    }
);

lib = {
    swipe: 'left',
    noSwipe: false,
    isMobile: false,
    initGoogleMaps: function () {
//* GMAPS ------------------------------------------- */
        var map;
        var initialize;
        var MY_MAPTYPE_ID = 'custom_style';


        initialize = function () {
            var latLng = new google.maps.LatLng(50.730218, 4.510087); // coordonnées
            var myOptions = {
                zoom: 14,
                center: latLng,
                mapTypeId: google.maps.MapTypeId.TERRAIN, // HYBRID, ROADMAP, SATELLITE, TERRAIN
                maxZoom: 20
            };

            map = new google.maps.Map(document.getElementById('map'), myOptions);

            var marker = new google.maps.Marker({
                position: latLng,
                map: map,
                title: "Big Bad Wolf",
                icon: "/assets/img/marker.gif"
            });

            /*var infoWindow = new google.maps.InfoWindow({
                position: latLng
            });

            google.maps.event.addListener(marker, 'click', function () {
                infoWindow.open(map, marker);
            });*/

            google.maps.event.addDomListener(window, "resize", function () {
                var center = map.getCenter();
                google.maps.event.trigger(map, "resize");
                map.setCenter(center);
            });

        };

        initialize();


        var styles = [
            {
                stylers: [
                    {hue: "#000000"},
                    {saturation: -100},
                    {lightness: -5},
                    {gamma: 1.1}

                ]
            }, {
                featureType: "road",
                elementType: "geometry",
                stylers: [
                    {lightness: 100},
                    {visibility: "simplified"}
                ]
            }, {
                featureType: "road",
                elementType: "labels",
                stylers: [
                    /*{ visibility: "off" }*/
                ]
            }
        ];

        map.setOptions({styles: styles});
    },
    initAutoScroll: function () {
        var sections = $('section');
        var timer;
        var scrolling = false;

        $(window).bind('mousewheel', function (e) {
            if (scrolling === true) {
                $('html,body').stop();
                clearTimeout(timer);
            }
        });

        $(window).scroll(function (e) {
            clearTimeout(timer);

            var windowTop = $(document).scrollTop();
            var windowHeight = $(window).height();
            var documentHeight = $(document).height();

            if (windowHeight + windowTop >= documentHeight) {
                return;
            }

            timer = setTimeout(function () {
                var windowTop = $(document).scrollTop();
                var diff = null;
                var section = null;

                sections.each(function () {
                    var top = $(this).offset().top;
                    if (diff === null) {
                        diff = Math.abs(top - windowTop);
                        section = $(this);
                    }
                    else if (diff > Math.abs(top - windowTop)) {
                        diff = Math.abs(top - windowTop);
                        section = $(this);
                    }
                });

                if (
                    section !== null
                    && diff <= 250
                ) {
                    scrolling = true;
                    $('html,body').animate({
                            scrollTop: section.offset().top
                        }, 'easeinout'
                        , function () { // On complete
                            scrolling = false;
                        });
                }

            }, 1250);
        });
    },
    showCategories: function (container) {
        setTimeout(function () {
            $(container).addClass('show-cat');
        }, 1000);

        setTimeout(function () {
            $(container).removeClass('show-cat');
        }, 3000);
    },
    initSwipe: function () {
        $(".no-swipe").swipe({
            swipeLeft:function(event, direction, distance, duration, fingerCount) {
                lib.noSwipe = true;
            },
            swipeRight:function(event, direction, distance, duration, fingerCount) {
                lib.noSwipe = true;
            }
        });

        $(".toggle-wfilter").swipe({
            swipeRight: function () {
                $(".toggle-wfilter").stop().animate({right: '-250px',opacity:0});
                lib.noSwipe = true;
            }
        });

        $("#wrapper").swipe({
            swipeLeft:function(event, direction, distance, duration, fingerCount) {
                if (
                    lib.swipe === 'left'
                    || lib.noSwipe === true
                ) {
                    lib.noSwipe = false;
                    return true;
                }
                $(".toggle-wfilter").stop().animate({right: '-250px',opacity:0});
                $("#sidebar-toggle").trigger('click');
                lib.swipe = 'left';
            },
            swipeRight:function(event, direction, distance, duration, fingerCount) {
                if (
                    lib.swipe === 'right'
                    || lib.noSwipe === true
                ) {
                    lib.noSwipe = false;
                    return true;
                }
                $(".toggle-wfilter").stop().animate({right: '-250px',opacity:0});
                $("#sidebar-toggle").trigger('click');
                lib.swipe = 'right';
            }
        });

        $("#sidebar").swipe({
            swipeLeft:function(event, direction, distance, duration, fingerCount) {
                if (lib.swipe === 'left') {
                    return true;
                }
                $("#sidebar-toggle").trigger('click');
                lib.swipe = 'left';
            }
        });
    }
};