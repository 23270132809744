// require('./libs/all.min.new.js');
require('./script.js');

// $(document).ready (function () {
//     if (!lib.isMobile) {
//         $('html, body').css("overflow","hidden");
//     }
//     if (!lib.isMobile) {
//         lib.initNicescroll(false);
//     } else {
//         lib.initSwipe();
//     }
// });